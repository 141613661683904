@import "../../../Homepage/public.sass";
$blue: #0c59eb;
$blue-dark: #172439;
$white: #fff;

.content002 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 6em 0 0 0;
  padding: 2em 0;

  @include mobile {
    padding: 5vw;
  }

  .block-001 {
    width: 90%;
    display: flex;

    @include mobile {
      flex-direction: column !important;
    }

    .demo {
      width: 50%;
      padding: 1em;
      margin-top: 1em;

      @include mobile {
        width: 100%;
      }
    }

    .text-group {
      width: 50%;
      padding: 1em;

      .title-001 {
        font-size: 4.5em;
        color: blue-dark;
        line-height: 1.3;
        font-weight: 700;
        margin: 0;
      }

      .title-002 {
        font-size: 4.5em;
        color: blue-dark;
        line-height: 1.3;
        font-weight: 700;
        margin: 0;
      }

      .title-003 {
        font-size: 1.2em;
      }

      .text-list {
        margin-left: 1em;
        .text-list-item {
          margin-bottom: 0.5em;
        }
      }

      @include mobile {
        width: 100%;
        margin-top: 2em;

        .title-001 {
          font-size: 2em;
        }

        .title-002 {
          font-size: 2em;
        }
      }
    }
  }
}
