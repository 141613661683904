@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content; }
  @keyframes #{$name} {
    @content; } }
.button-play {
  background: transparent;
  border: none;
  color: #ffffff;
  margin-top: 15px;
  cursor: pointer;
  .icon {
    border-radius: 50%;
    width: 45px;
    height: 45px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    margin-right: 10px;
    &:before, &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background: #fff; }
    &:after {
      opacity: 0.5;
      z-index: -1;
      animation: zooming 2s linear infinite; }
    svg {
      fill: url('#color');
      width: 35%;
      height: 35%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-40%, -50%); } }
  .text {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: auto;
      right: 0;
      width: 0%;
      height: 1px;
      background: #fff;
      transition: width .7s; } }
  &:hover {
    .text {
      &::after {
        width: 100%;
        left: 0;
        right: auto; } } } }
@include keyframes(circle) {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }
@include keyframes(zooming) {
  0% {
    opacity: 0;
    transform: scale(0.5); }
  50% {
    opacity: .8;
    transform: scale(1); }
  70% {
    opacity: 0.2;
    transform: scale(1.3); }
  95% {
    opacity: 0;
    transform: scale(1.4); }
  100% {
    opacity: 0;
    transform: scale(0.5); } }

