@import "../public";

#section-3 {
  .slick-slide {
    height: 100vh;
  }

  .page1 {
    width: 100vw;
    padding: 5vh 5vw;
    position: absolute;

    .container {
      width: 100%;
      display: flex;

      .container-left {
        width: 50%;
        padding: 1vw;

        .word {
          .description {
            margin-top: 10px;
          }
        }
      }

      .container-right {
        width: 50%;
        padding: 1vw;
        .img-main {
          width: 100%;
        }
      }
    }

    @include mobile {
      .container {
        flex-direction: column-reverse;
        flex-wrap: wrap;

        .container-left {
          width: 100%;
        }

        .container-right {
          width: 100%;
          .img-main {
            margin-bottom: 5vh;
          }
        }
      }
    }

    .btn-next {
      display: flex;
      position: absolute;
      margin-top: 30vh;
      right: 10vw;
      // bottom: 5vh;

      @include mobile {
        position: relative;
        justify-content: center;
        bottom: unset;
        right: unset;
        margin-top: 2rem;
      }
      .warp {
        display: flex;
        align-items: center;
        border: 2px white solid;
        border-radius: 50px;
        padding: 0.5rem 1rem;
        &:hover {
          cursor: pointer;
        }
        .icon {
          height: 2rem;
          margin-left: 1rem;
          animation-name: ArrowNext;
          animation-iteration-count: infinite;
          animation-duration: 4s;
          @include mobile {
            height: 1rem;
          }
        }
        .text {
          font-family: MStiffHeiHK;
          font-size: 1.5rem;
          @include mobile {
            font-size: 1rem;
          }
        }
      }
    }

    @keyframes ArrowNext {
      0% {
        opacity: 0;
      }
      25% {
        opacity: 1;
      }
      50% {
        opacity: 0;
      }
      75% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  }
}
