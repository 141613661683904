@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content; }
  @keyframes #{$name} {
    @content; } }
.open-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background: rgba(#000, 0.55);
  padding: 5vh 5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  .close-button {
    background: none;
    border: none;
    position: absolute;
    right: 0px;
    top: 0px;
    width: 80px;
    height: 80px;
    padding: 0;
    cursor: pointer;
    background: #fff;
    z-index: 2;
    span {
      display: block;
      width: 50%;
      height: 50%;
      margin: auto;
      position: relative;
      &::before, &::after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        width: 100%;
        height: 2px;
        background: #000;
        margin-left: -50%; }
      &::before {
        transform: rotate(45deg); }
      &::after {
        transform: rotate(-45deg); } }
    &:hover {
      span {
        transition: transform 0.35s;
        transform: rotate(90deg); } } }
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 90%;
    width: 100%;
    height: 100%;
    margin: auto;
    .video-wrap {
      height: 0;
      padding-bottom: 56.25%; } }
  &.white {
    padding: 2vh 2vw;
    .close-button {
      width: 60px;
      height: 60px;
      background: #4bb4ff;
      span {
        &::before, &::after {
          background: #fff; } }
      @media screen and ( max-width: 640px ) {
        width: 30px;
        height: 30px; } }
    .container {
      width: 100%;
      height: auto;
      padding: 40px;
      background: #14234d;
      position: relative;
      transition: transform 0.25s;
      .video {
        width: 65%; }
      .detail {
        width: 35%;
        padding-left: 30px;
        color: #fff;
        .title {
          font-size: 3vw;
          margin: 0;
          font-weight: bold; } }
      @media screen and ( max-width: 1000px ) {
        display: block;
        .video {
          width: 100%; }
        .detail {
          width: 100%;
          padding-left: 0px;
          margin-top: 20px;
          .description {
            font-size: 0.75rem; } } }
      @media screen and ( max-width: 640px ) {
        padding: 20px;
        .detail {
          .title {
            font-size: 1.25rem; } } } }
    &.show {
      .container {
        animation: runIn 0.5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards; } } } }
@include keyframes(zoomIn) {
  0% {
    opacity: 0;
    transform: scale(0.95) translateY(-50px); }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1; } }
@include keyframes(runIn) {
  0% {
    transform: translateX(-1500px) skewX(30deg) scaleX(1.3); }
  70% {
    transform: translateX(30px) skewX(0deg) scaleX(1); }
  100% {
    transform: translateX(0px) skewX(0deg) scaleX(1); } }
