@import "../public";

#section-1 {
  padding: 5vh 5vw;

  .title {
    position: relative;
    text-align: center;
    font-size: 4em;
    margin-top: -0.5em;

    @media screen and (max-width: 640px) and (orientation: portrait) {
      position: relative;
      font-size: 2em;
    }
  }

  .container {
    width: 100%;
    display: flex;

    .container-left {
      width: 50%;
      padding: 1vw;

      .word {
        .description {
          margin-top: 10px;
        }
      }
    }

    .container-right {
      width: 50%;
      padding: 1vw;
    }
  }

  @include mobile {
    .container {
      flex-direction: column-reverse;
      flex-wrap: wrap;

      .container-left {
        width: 100%;
      }

      .container-right {
        width: 100%;
        .img-main {
          margin-bottom: 5vh;
        }
      }
    }
  }
}
