@import "../../utils/var";

@keyframes figure_1 {
	0% {
		transform: translate(0em,0em);
	}
	50% {
		transform: translate(3em,4em);
	}
	80% {
		transform: translate(-2em,-5em);
	}
	100% {
		transform: translate(0em,0em);
	}
}
@keyframes figure_2 {
	0% {
		transform: translate(0em,0em);
	}
	50% {
		transform: translate(-3em,9em);
	}
	70% {
		transform: translate(4em,3em);
	}
	100% {
		transform: translate(0em,0em);
	}
}
@keyframes figure_3 {
	0% {
		transform: translate(0em,0em);
	}
	50% {
		transform: translate(-3em,4em);
	}
	60% {
		transform: translate(2em,-5em);
	}
	100% {
		transform: translate(0em,0em);
	}
}
@keyframes figure_4 {
	0% {
		transform: translate(0,0);
	}
	50% {
		transform: translate(79vh,-23vh);
	}
	70% {
		transform: translate(102vh,-3vh);
	}
	100% {
		transform: translate(0em,0em);
	}
}
@keyframes figure_15 {
	0% {
		transform: translate(0em,0em);
	}
	50% {
		transform: translate(-8em,7em);
	}
	80% {
		transform: translate(-18em,15em);
	}
	100% {
		transform: translate(0em,0em);
	}
}
@keyframes figure_6 {
	0% {
		transform: translate(0em,0em);
	}
	50% {
		transform: translate(22em,-18em);;
	}
	80% {
		transform: translate(22em,-14em);
	}
	100% {
		transform: translate(0em,0em);
	}
}
@keyframes figure_7 {
	0% {
		transform: translate(0,0);
	}
	50% {
		transform: translate(7vh,8vh);
	}
	100% {
		transform: translate(0,0);
	}
}
@keyframes figure_8 {
	0% {
		transform: translate(0,0);
	}
	50% {
		transform: translate(52vh,29vh);
	}
	100% {
		transform: translate(0,0);
	}
}
@keyframes figure_10 {
	0% {
		transform: translate(0em,0em);
	}
	50% {
		transform: translate(3em,4em);
	}
	80% {
		transform: translate(10em,15em);
	}
	100% {
		transform: translate(0em,0em);
	}
}
@keyframes figure_12 {
	0% {
		transform: translate(0em,0em);
	}
	50% {
		transform: translate(-85vh,8em);
	}
	100% {
		transform: translate(0em,0em);
	}
}
@keyframes figure_13 {
	0% {
		transform: translate(0em,0em);
	}
	50% {
		transform: translate(-92vh,3em);
	}
	100% {
		transform: translate(0em,0em);
	}
}
@keyframes figure_14 {
	0% {
		transform: translate(0em,0em);
	}
	50% {
		transform: translate(-30vh,30vh);
	}
	100% {
		transform: translate(0em,0em);
	}
}
@keyframes figure_19 {
	0% {
		transform: translate(0em,0em);
	}
	50% {
		transform: translate(-9em,-24em);
	}

	80% {
		transform: translate(-19em,-34em);
	}
	100% {
		transform: translate(0em,0em);
	}
}

.backgroundDots {
	position: fixed;
	width: 70%;
	height: 70%;
	display: block;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	z-index: 0;
  > * {
    fill: $pink;
    opacity: .2;
  }
  .figure {
    &_1 {
      animation:figure_1 17s linear 10;
    }
    &_2 {
      animation:figure_2 25s linear 10;
    }
    &_3 {
      animation:figure_3 30s linear 10;
    }
    &_4 {
      animation:figure_4 36s linear 10;
    }
    &_6 {
      animation:figure_6 30s linear 10;
    }
    &_7 {
      animation:figure_7 25s linear 10;
    }
    &_8 {
      animation:figure_8 30s linear 10;
    }
    &_9 {
      animation:figure_2 30s linear infinite;
    }
    &_10 {
      animation:figure_10 30s linear infinite;
    }
    &_12 {
      animation:figure_12 30s linear infinite;
    }
    &_13 {
      animation:figure_13 40s linear infinite;
    }
    &_13 {
      animation:figure_14 40s linear infinite;
    }
    &_14 {
      animation:figure_14 40s linear infinite;
    }
    &_15 {
      animation:figure_15 28s linear infinite;
      animation-delay: 1s;
      transform: translate(0em,0em);
    }
    &_19 {
      animation:figure_19 28s linear infinite;
      transform: translate(0em,0em);
    }
  }
}