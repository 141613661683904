@import '../public';

.section-10 {
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    display: flex;
    align-items: center;
    color: $color-blue-light;
    font-family: MStiffHeiHK;
    font-size: 6vmin;
    font-style: italic;
    line-height: 1.5; }
  .img-arrow-down {
    width: 1.5rem;
    margin-top: 2rem; }

  .line-divider {
    width: 80%;
    height: 2px;
    border-radius: 2px;
    background: $gradual-01;
    margin-top: 2rem; }

  .application {
    max-width: 1200px;
    width: 100%;
    .text {
      color: $color-blue-light;
      font-size: 4vmin;
      padding: 2rem; }
    .list {
      display: flex;
      flex-direction: column;
      padding: 1rem;
      .list-item {
        display: flex;
        flex-direction: column;
        .list-title {
          display: flex;
          align-items: center;
          color: $color-blue-light;
          font-family: MStiffHeiHK;
          font-size: 3rem;
          font-style: italic;
          line-height: 1.5;
          @include mobile {
            font-size: 1.5rem; } }
        .list-content {
          font-size: 2rem;
          margin-bottom: 4rem;
          padding: 0px 0.5rem;
          @include mobile {
            font-size: 1rem;
            margin-bottom: 2rem; }
          .list-image {
            margin-top: 40px;
            width: 400px;
            @include mobile {
              margin-top: 20px;
              width: 200px; } } } } } } }
