.pop-video {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .pop-video-btn-close {
    width: 3vw;
    height: 3vw;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
  }

  #pop-video-player {
    width: 80%;
  }
}
