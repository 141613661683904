@import '../public';

.section-08 {
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    display: flex;
    align-items: center;
    color: $color-blue-light;
    font-family: MStiffHeiHK;
    font-size: 6vmin;
    font-style: italic;
    line-height: 1.5; }
  .img-arrow-down {
    width: 1.5rem;
    margin-top: 2rem; }

  .technology {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 1200px;
    width: 100%;
    margin-top: 5rem;
    margin-bottom: 5rem;
    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 25%;
      @include mobile {
        width: 50%; }
      .icon {
        width: 90%;
        margin: 5%;
        @include mobile {
          width: 80%;
          margin: 10%; } }
      .label {
        font-size: 1.5rem;
        @include mobile {
          font-size: 1.2rem; } } } } }
