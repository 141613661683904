@import "../Section1/index.scss";

#section-2 {
  padding: 5vh 5vw;

  .container {
    width: 100%;
    display: flex;

    .container-left {
      width: 50%;
      padding: 1vw;

      .word {
        .description {
          margin-top: 10px;
        }
      }
    }

    .container-right {
      width: 50%;
      padding: 1vw;
    }
  }

  @include mobile {
    .container {
      flex-direction: column;
      flex-wrap: wrap;

      .container-left {
        width: 100%;
        .img-main {
          margin-bottom: 5vh;
        }
      }

      .container-right {
        width: 100%;
      }
    }
  }
}
