.footer {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  .btn-main-page {
    padding: 10px 20px;
    background-color: #172439;
    color: white;
    font-size: 1.5em;
    border-radius: 10px;
    cursor: pointer;
  }
}
