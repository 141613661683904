.main-container {
  overflow: hidden; }
.backgorund {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh; }
.page {
  padding: 50px 0;
  width: 100%;
  min-height: 100vh;
  margin: auto;
  .page-title {
    font-size: 4.5vw;
    color: #fff;
    line-height: 1.3;
    font-weight: 700;
    margin: 0; }
  @media screen and ( max-width:1024px) {
    min-height: inherit;
    .page-title {
      font-size: 7vw; }
    .description {
      font-size: 2vw; } }
  @media screen and ( max-width: 640px ) {
    .description {
      font-size: 12px; } } }
.letter {
  word-break: break-word;
  position: relative; }
