@import '../public';

.section-09 {
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    display: flex;
    align-items: center;
    color: $color-blue-light;
    font-family: MStiffHeiHK;
    font-size: 6vmin;
    font-style: italic; }
  .img-arrow-down {
    width: 1.5rem;
    margin-top: 2rem; }

  .system {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 840px;
    width: 100%;
    .content-text {
      color: $color-blue-light;
      font-size: 4vmin;
      padding: 2rem; } }
  .img-devices {
    width: 100vw; }
  .img-oneClick {
    width: 100vw;
    margin-bottom: 5rem; } }
