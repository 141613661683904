@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content; }
  @keyframes #{$name} {
    @content; } }
#section-4 {
  // max-width: 960px
  margin-top: 10vh;
  padding: 0 5vw;
  .page-title {
    text-align: center; }
  .page-title-sub {
    text-align: center;
    margin-bottom: 10vh; }
  .box {
    width: 33.33%;
    display: inline-block;
    vertical-align: top;
    font-size: 0;
    padding: 0 20px;
    margin-bottom: 50px;
    text-align: center;
    @media screen and ( max-width: 1000px ) {
      width: 50%; }
    >div {
      cursor: pointer;
      display: inline-block;
      &:hover {
        .block-icon {
          transform: scale(1.3);
          .button-play {
            transform: translate(-50%, -50%) scale(2);
            opacity: 1; } } } }
    .block-icon {
      width: 120px;
      margin: auto;
      display: flex;
      border: 1px solid rgba(#fff, 0.15);
      border-radius: 50%;
      position: relative;
      transition: transform 0.7s, opacity 0.7s;
      &:before {
        content: "";
        border: 1px solid rgba(#fff, 0.15);
        border-radius: 50%;
        width: 100%;
        height: 100%;
        position: absolute;
        animation: core 2s linear 0.2s infinite; }
      &::after {
        content: "";
        display: block;
        padding-bottom: 100%; }
      .button-play {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale(0);
        margin: 0;
        opacity: 0;
        transition: opacity 0.5s, transform 0.5s;
        .icon {
          margin: 0; } }
      .core {
        position: relative;
        width: 60%;
        margin: auto;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: radial-gradient(ellipse at center,rgba(162,34,125,0) 0,rgba(162,34,125,0) 27%,rgba(162,34,125,.37) 48%,rgba(162,34,125,.87) 60%,#a2227d 100%);
        &::after {
          content: "";
          display: block;
          padding-bottom: 100%; }
        svg {
          width: 50%; } } }
    .block-title {
      text-align: center;
      font-size: 1.75rem;
      // max-width: 115px
      margin: 20px auto;
      line-height: 1.3;
      @media screen and ( max-width: 1000px ) {
        font-size: 1.25rem; } } }
  .block-line {
    padding: 80px 20px;
    >div {
      display: inline-block;
      vertical-align: top; }
    .block-icon {
      width: 120px;
      display: flex;
      float: left;
      border: 1px solid rgba(#fff, 0.15);
      border-radius: 50%;
      position: relative;
      transform: scale(0.7);
      opacity: 0;
      transition: transform 0.7s, opacity 0.7s;
      &:before {
        content: "";
        border: 1px solid rgba(#fff, 0.15);
        border-radius: 50%;
        width: 100%;
        height: 100%;
        position: absolute; }
      &::after {
        content: "";
        display: block;
        padding-bottom: 100%; }
      .core {
        position: relative;
        width: 60%;
        margin: auto;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: radial-gradient(ellipse at center,rgba(162,34,125,0) 0,rgba(162,34,125,0) 27%,rgba(162,34,125,.37) 48%,rgba(162,34,125,.87) 60%,#a2227d 100%);
        &::after {
          content: "";
          display: block;
          padding-bottom: 100%; }
        svg {
          width: 50%; } }
      .text {
        position: absolute;
        top: -0px;
        left: -0px;
        font-size: 1.06rem;
        transform: translateY(-40px);
        opacity: 0;
        transition: transform 0.7s, opacity 0.7s; } }
    .block-title {
      width: calc(50% - 60px);
      padding: 0 50px;
      font-size: 1.75rem;
      line-height: 1.3; }
    .block-description {
      width: calc(50% - 60px);
      padding: 15px 10px 0 0;
      font-weight: 300;
      position: relative;
      line-height: 1.5;
      .line {
        position: absolute;
        width: 100%;
        top: 0;
        height: 10px;
        border-top: 2px solid rgba(#fff, 0.25);
        border-right: 2px solid rgba(#fff, 0.25);
        &:before, &:after {
          content: '';
          position: absolute;
          background: #fff;
          transition: width 0.7s, height 0.7s; }
        &:before {
          width: 0%;
          height: 2px;
          top: -2px;
          right: 0; }
        &:after {
          width: 2px;
          height: 0px;
          right: -2px;
          bottom: 0;
          top: auto;
          transition-delay: 0.7s; } } }
    @media screen and ( max-width: 1000px ) {
      .block-icon {
        opacity: 1;
        transform: scale(1.3); } }
    @media screen and ( max-width: 768px ) {
      width: 300px;
      text-align: center;
      margin: auto;
      >div {
        display: block;
        margin: auto; }
      .block-icon {
        float: none;
        margin: auto; }
      .block-title {
        padding: 0;
        margin-top: 30px;
        width: 100%; }
      .block-description {
        margin-top: 20px;
        width: 100%; } }
    &:hover {
      .block-icon {
        transform: scale(1.3);
        opacity: 1;
        &:before {
          animation: core 2s linear 0.2s infinite; }
        .text {
          transform: translateY(0px);
          opacity: 1; } }
      .block-description {
        .line {
          &:before, &:after {}
          &:before {
            width: 100%;
            left: 0;
            right: auto; }
          &:after {
            height: 10px;
            top: -2px;
            bottom: auto; } } } } }

  .line_benefits_icon_1 {
    .cls-1 {
      fill: none;
      stroke: #4bb4ff;
      stroke-width: 1px; }
    .cls-1,
    .cls-2 {
      fill-rule: evenodd; }
    .cls-2 {
      fill: url(#linear-gradient); } }
  .line_benefits_icon_2 {
    .st0 {
      fill: none;
      stroke: #4bb4ff; }
    .st1 {
      fill: url(#Rectangle_834_copy_7_1_); } }
  .line_benefits_icon_3 {
    .st0 {
      fill: none;
      stroke: #4bb4ff; } } }

@include keyframes(core) {
  0% {}
  transform: scale(0.4);
  opacity: 1;
  80% {}
  transform: scale(0.95);
  opacity: 1;
  90% {}
  transform: scale(0.95);
  opacity: 0;
  95% {}
  transform: scale(1);
  opacity: 0;
  100% {}
  transform: scale(0.4);
  opacity: 0; }
