header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  background: linear-gradient(to top, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 60%);
  .container {
    display: flex;
    padding: 10px 3vw;
    align-items: center;
    justify-content: space-between; }
  .logo {
    font-size: 2rem;
    font-weight: 700;
    margin: 0; }
  .menu {
    margin: 0px;
    opacity: 0;
    transition: opacity 0.5s;
    &.show {
      opacity: 1; }
    li {
      display: inline-block;
      padding: 0px 20px;
      cursor: pointer;
      font-size: 1.2rem;
      &.bordered {
        border: 1px solid #4bb4ff;
        background: rgba(#4bb4ff, 0.15);
        color: #4bb4ff;
        border-radius: 3px; }
      &:hover {
        text-decoration: underline; } }

    .dropdown {
      position: relative;
      display: inline-block;
      cursor: pointer;
      &:hover {
        .dropdown-content {
          display: block; } }

      .dropbtn {
        background-color: transparent;
        color: white;
        font-size: 1.2rem;
        border: none;
        padding: 0px; }

      .dropdown-content {
        display: none;
        position: absolute;
        background-color: rgba(0,0,0, 0.2);
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 1;

        .dropdown-link {
          width: 150px;
          color: white;
          padding: 12px 16px;
          text-decoration: none;
          display: block; } } }

    @media screen and ( max-width: 480px ) {
      li {
        padding: 0px 10px;
        font-size: 1rem; }

      .dropdown {
        .dropbtn {
          font-size: 1rem; } } } } }
