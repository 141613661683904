@import "../../../Homepage/public.sass";
@import "../base.scss";

#application-esg {
  .section-01 {
    font-size: calc(1vw + 1vh + 0.5vmin) !important;
    max-width: none;
    padding-left: 3.5em;
    padding-top: 3.5em;
    min-height: 100vh;

    @include mobile {
      padding: 2em;
    }

    .title-001 {
      font-size: clamp(30px, 2.5em, 80px);
      display: block;
      color: $blue;
      font-weight: 700;
    }

    .title-002 {
      font-size: clamp(20px, 1.4em, 45px);
      color: $blue-dark;
      display: block;
      font-weight: 600;

      @include mobile {
        margin-top: 1em;
      }
    }

    .title-003 {
      font-size: clamp(20px, 1.1em, 30px);
      color: $blue-dark;
      margin-top: 1em;
      line-height: 0.3;
      max-width: 800px;
      word-wrap: break-word;
      line-height: 1.8em;

      @include mobile {
        margin: 0;
        line-height: 1.8em;
      }
    }

    .title-003-highlight {
      font-weight: bold;
      color: $blue;
      margin-left: 0.1em;
      margin-right: 0.1em;
      font-size: 110%;
    }

    .contact-block {
      display: flex;
      align-items: center;

      @include mobile {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 3em;
      }

      .btn-contact {
        border-radius: 10em;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        transition: background-color 200ms ease;
        padding: 0.3em 1.4em;
        background: $blue;
        color: $white;

        @include mobile {
          width: 80%;
          // height: 3em;
          font-size: 1.5em;
        }
      }

      .btn-line{
        @include mobile {
          margin-top: 1em;
          font-size: 1.5em;
        }
      }
    }

    .img-001 {
      width: 50%;
      max-width: 900px;
      margin-left: 49%;
      margin-top: -100px;

      @include mobile {
        width: 100%;
        margin: 5em 0 0 0;
      }
    }
  }
}
