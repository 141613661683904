@import "./base.scss";

#application-esg {
  min-height: 100vh;
  background-color: white;
  position: relative;
  font-family: "Noto Sans TC", "微軟正黑體", Microsoft Yahei, Poppins, Helvetica, Arial;
  font-weight: 400;
  line-height: 1.5;
  color: $blue-dark;
  text-align: left;
  font-size: 16px;
}
