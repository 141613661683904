@import '../public';

.section-05 {
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    max-width: 900px;
    width: 100%; }
  .title-sub {
    max-width: 600px;
    width: 100%; }
  .title-sub-text {
    color: $color-blue-light;
    font-family: MStiffHeiHK;
    font-size: 6vmin;
    font-style: italic; }

  .content-01 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5rem;
    padding: 1.5rem;
    max-width: 1200px;
    width: 90%;
    border: 2px solid;
    border-radius: 5px;
    -webkit-border-image: $gradual-01;
    border-image-slice: 1;
    .earth {
      max-width: 600px;
      width: 100%; }
    .text {
      color: $color-blue-light;
      font-family: MStiffHeiHK;
      font-size: 3.5vmin; } }

  .img-question {
    width: 100vw; }

  .content-02 {
    padding: 1.5rem;
    .border {
      margin-top: 1.5rem;
      border: 1px dashed rgb(20,26,65);
      background: $gradual-01;
      background-origin: border-box;
      .content {
        background-color: rgb(20,26,65);
        padding: 1.5rem;
        .text {
          color: $color-blue-light;
          font-family: MStiffHeiHK;
          font-size: 4vmin; } } } } }
