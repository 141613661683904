.zig-video {
  display: flex;
  align-items: center;
  justify-content: center;
  .wrap {
    width: 100%; }
  .title {
    text-align: center;
    font-size: 2em;
    margin-top: 1em; }
  .title-sub {
    text-align: center;
    margin: 2em; }
  .img-oneClick-warp {
    width: 100vw;
    height: 50vw;
    margin-bottom: 5rem;
    .icons-oneClick {
      width: 40%;
      height: 50%;
      margin-top: 8%;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-wrap: wrap;
      position: absolute; }
    .img-oneClick {
      width: 100%;
      height: 100%; } }

  .video-container {
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    position: relative;
    overflow: hidden;
    margin-bottom: 2rem;
    video {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      padding: 0px 5%; } } }
