@font-face {
  font-family: MStiffHeiHK;
  src: url(/assets/font/MStiffHeiHK-UltraBold.ttf); }

@mixin mobile {
  @media(max-width:767px) {
    @content; } }

$color-blue-light: rgba(0, 255, 255, 1);
$gradual-01: -webkit-linear-gradient(0deg, rgba(181, 3, 192, 1)20%,rgba(96,27,144,1)40% ,rgba(56,34,115,1)60%,rgba(36, 59, 121, 1)80%, rgba(0, 165, 188, 1)100%);

.border-gradual {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em;
  border: 2px solid;
  -webkit-border-image: $gradual-01;
  border-image-slice: 1; }
.line-bottom {
  position: relative;
  right: 5%;
  width: 110%;
  height: 2px;
  border-radius: 2px;
  background: $gradual-01; }
