.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: -webkit-radial-gradient(center, ellipse cover, rgba(0, 92, 255, 1),rgba(0, 64, 194, 0.6), rgba(0, 37, 105, 0.6),rgba(0, 26, 68, 0.02),rgba(0, 0, 0, 0.02));
  .circle-2 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 12.5px;
    height: 12.5px;
    border: 1px white solid;
    border-radius: 50%;
    box-shadow: 0px 0px 5px 0px rgba(0, 92, 255, 1);
    .circle-3 {
      width: 5px;
      height: 5px;
      border: 1px white solid;
      background: white;
      border-radius: 50%;
      box-shadow: 0px 0px 2px 2px rgba(0,255,255,1), 0px 0px 5px 2.5px rgba(255,255,255,0.7); } } }
