.back-stage {
  display: flex;
  flex-direction: column;
  .back-stage-table {
    margin: 1rem;
    border: 1px white solid;
    .cell {
      border: 1px white solid;
    }
  }
  .pages {
    align-self: flex-end;
    margin: 1rem;
    .pages-btn {
      width: 30px;
      height: 30px;
      border-radius: 6px;
      background-color: transparent;
      border-style: solid;
      color: white;
      cursor: pointer;
    }
    .pages-btn-clicked {
      background-color: white;
      color: black;
    }
  }
}
