.pop-tip {
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.1);
  z-index: 999;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .content {
    width: 300px;
    height: 200px;
    background-color: aliceblue;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color: black;
    .result {
      width: 40px;
      height: 40px;
      margin: 10px;
    }
    .btn-confirm {
      border-radius: 30px;
      min-width: 100px;
      min-height: 30px;
      background-color: transparent;
      color: white;
      border-style: solid;
      margin: 1rem;
      cursor: pointer;
      background-color: #4bb4ff;
      border-color: #4bb4ff;
    }
  }
}
