@import '../public';

.section-06 {
  display: flex;
  flex-direction: column;
  align-items: center;

  .content-01 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    .text {
      font-family: MStiffHeiHK;
      font-size: 6vmin;
      text-shadow: 0rem 0rem 1rem rgba(255,255,255,0.8); }
    .text-en {
      font-family: MStiffHeiHK;
      font-size: 3vmin;
      text-align: center; }
    .img-arrow-down {
      width: 1.5rem;
      margin-top: 2rem;
      margin-bottom: 2rem; }
    .description {
      font-size: 1.5em;
      font-weight: bold; } }

  .content-02 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1200px;
    width: 100%;
    .block {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 3rem 3rem 3rem;
      width: 100%;
      max-width: 500px;
      img {
        width: 10rem;
        height: 10rem; }
      .title {
        font-family: MStiffHeiHK;
        font-size: 3rem;
        text-shadow: 0rem 0rem 1rem rgba(255,255,255,0.8);
        text-align: center; }
      .title-sub {
        color: $color-blue-light;
        font-size: 1.5rem;
        letter-spacing: 1px;
        width: 100%; }
      .item {
        display: flex;
        .img-number {
          width: 3rem;
          height: 3rem;
          margin-top: 1rem; }
        .text {
          color: $color-blue-light;
          font-size: 1.2rem;
          width: 100%; } } }
    .btn-more {
      background-color: cyan;
      color: black;
      border: 0;
      border-radius: 20px;
      padding: 10px 20px;
      cursor: pointer; } }

  .content-03 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    .text {
      font-family: MStiffHeiHK;
      font-size: 6vmin;
      text-shadow: 0rem 0rem 1rem rgba(255,255,255,0.8);
      text-align: center; }
    img {
      width: 20vmin; } } }
