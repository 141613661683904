@mixin input-base {
  background-color: rgba(255, 255, 255, 0.05);
  border-color: transparent;
  height: 3.5rem;
  padding: 1rem;
  border-radius: 6px;
  margin-top: 2%;
  color: white;
}

@mixin btn-base {
  border-radius: 30px;
  min-width: 120px;
  min-height: 50px;
  background-color: transparent;
  color: white;
  border-style: solid;
  margin: 1rem;
  cursor: pointer;
}

#contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-size: 20px;

  .title {
    text-align: center;
    font-size: 1.5em;
    line-height: 1.5em;
    margin-top: 1.5em;

    @media screen and (max-width: 640px) and (orientation: portrait) {
      font-size: 1.2em;
      line-height: 1.2em;
      margin-top: 1.2em;
    }
  }

  #input-name {
    @include input-base;
    width: 49%;
    margin-right: 1%;
  }

  #input-email {
    @include input-base;
    width: 49%;
    margin-left: 1%;
  }

  #input-message {
    @include input-base;
    width: 100%;
    height: 10rem;
  }

  #btn-submit {
    @include btn-base;
    background-color: rgba(75, 180, 255, 1);
    border-color: rgba(75, 180, 255, 1);
    &:hover {
      background-color: rgba(75, 180, 255, 0.8);
    }
  }

  #btn-reset {
    @include btn-base;
    border-color: white;
    &:hover {
      background-color: rgba(255, 255, 255, 0.05);
    }
  }

  @media (max-width: 540px) {
    #input-name {
      width: 100%;
      margin-right: 0;
    }

    #input-email {
      width: 100%;
      margin-left: 0;
    }
  }
}
