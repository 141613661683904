@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@300;400;700&display=swap&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@400;700&display=swap');
body {
  font-size: 1rem;
  font-weight: 400;
  text-align: justify;
  line-height: 2;
  width: 100%;
  overflow-x: hidden;
  color: #fff;
  font-family: "Heebo", "Noto Sans TC", "Microsoft JhengHei", sans-serif;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -ms-overflow-style: scrollbar;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  background-color: #14234d; }
body,html {
  margin: 0; }
a, a:active, a:focus, a:hover, a:visited {
  text-decoration: none;
  outline: none; }
*,:active,:focus {
  outline: 0; }
h1,h2,h3,h4,h5,h6 {
  font-weight: 400;
  line-height: 1.7; }
label {
  font-weight: inherit; }
button,input,optgroup,option,select,textarea {
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  outline: 0; }
textarea {
  resize: vertical; }
ol.reset,ul.reset {
  margin: 0;
  padding: 0;
  list-style: none; }
button,code,input,kbd,samp,select,textarea,tt {
  font-size: 100%; }
*,:after,:before {
  box-sizing: border-box; }
.img-resize {
  max-width: 100%; }
