@import '../public';

.section-07 {
  display: flex;
  flex-direction: column;
  align-items: center;

  .img-logo {
    max-width: 840px;
    width: 100%; }

  .table {
    display: flex;
    width: 100%;
    max-width: 1200px;
    @include mobile {
      flex-wrap: wrap; }
    .title {
      font-family: MStiffHeiHK;
      text-align: center; }
    .left {
      width: 50%;
      color: gray;
      padding: 2rem;
      @include mobile {
        width: 100%; } }
    .right {
      width: 50%;
      color: $color-blue-light;
      padding: 2rem;
      @include mobile {
        width: 100%; } }
    .item {
      .img-fack {
        width: 100%;
        height: 20rem;
        border: 1px gray solid;
        @include mobile {
          height: 15rem; } }
      .content {
        height: 10vmin;
        font-size: 2.5vmin;
        @include mobile {
          height: 4rem;
          font-size: 1rem; } } } } }
