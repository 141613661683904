@import "../../utils/var";
.backgorundNoise {
  &::after {
    content: "";
    position: fixed;
    width: 100%;
    height: 100%;
    background: url("../../assets/images/noise.gif") repeat;
    opacity: 0.25;
    top: 0;
  }
}