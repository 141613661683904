.one-click-item {
  width: 30%;
  height: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 2% 0px;

  .one-click-icon {
    width: 80%;
    background-color: rgba(0,0,0,0.3);
    border-radius: 50%; }

  .one-click-text {
    background-color: rgba(0,0,0,0.5);
    padding: 5px 10px;
    margin-top: 5px;
    border-radius: 10px;
    .one-click-title {
      font-size: 1.5vw;
      font-weight: 'bold'; }

    .one-click-description {
      font-size: 1vw; } } }
