@import '../../public';

.more {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 5vw;
  .item {
    width: 25%;
    padding: 0 5% 5% 5%;
    @include mobile {
      width: 50%; }
    .item-label {
      text-align: center;
      font-size: 2vw;
      @include mobile {
        font-size: 5vw; } } }

  // ---- copy from section04
  .box {
    width: 100%;
    display: inline-block;
    vertical-align: top;
    font-size: 0;
    padding: 0 20px;
    margin-bottom: 30px;
    text-align: center;
    >div {
      cursor: pointer;
      display: inline-block;
      &:hover {
        .block-icon {
          transform: scale(1.3); }
        .button-play {
          transform: translate(-50%, -50%) scale(2);
          opacity: 1; } } }
    .block-icon {
      width: 100%;
      margin: auto;
      display: flex;
      border: 1px solid rgba(#fff, 0.15);
      border-radius: 50%;
      position: relative;
      transition: transform 0.7s, opacity 0.7s;
      &:before {
        content: "";
        border: 1px solid rgba(#fff, 0.15);
        border-radius: 50%;
        width: 100%;
        height: 100%;
        position: absolute;
        animation: core 2s linear 0.2s infinite; }
      &::after {
        content: "";
        display: block;
        padding-bottom: 100%; }
      .button-play {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale(0);
        margin: 0;
        opacity: 0;
        transition: opacity 0.5s, transform 0.5s;
        .icon {
          margin: 0; } }
      .core {
        position: relative;
        width: 60%;
        margin: auto;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: radial-gradient(ellipse at center,rgba(162,34,125,0) 0,rgba(162,34,125,0) 27%,rgba(162,34,125,.37) 48%,rgba(162,34,125,.87) 60%,#a2227d 100%);
        &::after {
          content: "";
          display: block;
          padding-bottom: 100%; }
        svg {
          width: 50%; } }
      .item-image {
        width: 100%; } }
    .block-title {
      text-align: center;
      font-size: 1.75rem;
      // max-width: 115px
      margin: 20px auto;
      line-height: 1.3;
      @media screen and ( max-width: 1000px ) {
        font-size: 1.25rem; } } } }
