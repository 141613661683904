@import '../public';

.raspberry-pi {
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    display: flex;
    align-items: center;
    color: $color-blue-light;
    font-family: MStiffHeiHK;
    font-size: 6vmin;
    font-style: italic;
    line-height: 1.5; }
  .img-arrow-down {
    width: 1.5rem;
    margin-top: 2rem; }

  .line-divider {
    width: 80%;
    height: 2px;
    border-radius: 2px;
    background: $gradual-01;
    margin-top: 2rem; }

  .application {
    max-width: 1200px;
    width: 100%;
    padding: 2rem;
    .text {
      color: $color-blue-light;
      font-size: 40px;
      @include mobile {
        font-size: 20px; } }
    .img-pi {
      float: right;
      width: 400px;
      margin-left: 2rem;
      margin-top: 2rem;
      @include mobile {
        width: 80%;
        float: unset;
        margin-left: 10%; } } } }
