.pop-contact {
  height: 250px;
  width: 390px;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 2;
  background-image: url("../../assets/images/banner-bg.svg");
  background-repeat: no-repeat;
  background-size: contain;

  @media screen and (max-width: 640px) and (orientation: portrait) {
    width: 100vw;
    background-size: cover;
  }

  .content {
    h2 {
      margin: 0px 0px 20px 0px;
    }
    p {
      margin: 0;
      line-height: 25px;
      margin: 0px 0px 20px 0px;
    }

    padding: 40px 20px;
    width: 220px;
    color: #00142f;
    font-weight: bold;

    .contact-button {
      width: 100%;
      margin-top: 10px;
      padding: 5px 10px;
      color: white;
      border-radius: 30px;
      border: 0;
      background-color: #ff6700;
      text-align: center;
      cursor: pointer;
    }

    .img-avatar {
      position: absolute;
      top: 43px;
      right: 20px;
      width: 170px;

      @media screen and (max-width: 640px) and (orientation: portrait) {
        top: 49px;
        right: 15px;
        width: 175px;
      }
    }
  }

  .close-button {
    position: absolute;
    top: -15px;
    left: -15px;
    background-color: white;
    border-radius: 50%;
    border: 0px;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    @media screen and (max-width: 640px) and (orientation: portrait) {
      top: 0px;
      right: 0px;
    }
  }
}
