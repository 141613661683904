@import "../../utils/var";

@keyframes layer {
	0% {
		top: -15%;
		opacity: 0;
	}
	20% {
		top: -15%;
		opacity: 1;
	}
	30% {
		top: -15%;
		opacity: 1;
	}
	40% {
		top: -15%;
		opacity: 1;
	}
	50% {
		top: -15%;
		opacity: 0;
	}
	60% {
		top:20%;
		opacity: 0;
	}
	70% {
		top: 20%;
		opacity: 1;
	}
	80% {
		top: 20%;
		opacity: 1;
	}
	90% {
		top: 20%;
		opacity: 0;
	}
	100% {
		top: -15%;
		opacity: 0;
	}
}

.backgroundShine {
  position: fixed;
  left: -15%;
  top: 0;
  width: 75vh;
  height: 75vh;
  opacity: 0;
  border-radius: 50%;
  z-index: 1;
  display: block;
  background: radial-gradient(ellipse at center,rgba(228,108,239,.27) 0,rgba(228,108,239,.01) 65%,rgba(255,255,255,0) 100%);
  animation:layer 20s  linear infinite;
  animation-delay: 9s;
}