@import "../../routes/Homepage/public.sass";

.divider {
  width: 100vw;
  display: flex;
  justify-content: center;
  .line {
    width: 80%;
    height: 2px;
    border-radius: 2px;
    background: $gradual-01;
    margin-top: 2rem; } }
