#banner-video {
  position: relative;
  width: 100%;
  overflow: hidden;
  .banner-text {
    position: absolute;
    z-index: 2;
    width: 100%;
    top: 50%;
    padding-left: 3vw;
    transform: translateY(-50%);
    opacity: 0;
    transition: opacity 0.5s;
    &.show {
      height: 100vh;
      width: 800px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding-bottom: 10vh;
      opacity: 1;
      text-align: start;
      font-size: 40px;

      @media screen and ( max-width: 640px ) and  (orientation: portrait) {
        width: 100vw;
        padding-right: 3vw;
        text-align: center;
        font-size: 30px; }

      @media screen and ( max-width: 640px ) and  (orientation: landscape) {
        padding-bottom: 10px; } }

    .title {
      margin: 0;
      text-align: start;
      font-size: 1em;
      line-height: 1em;

      @media screen and ( max-width: 640px ) and  (orientation: portrait) {
        text-align: center; } }

    .description {
      font-size: 0.5em;
      line-height: 2em;

      @media screen and ( max-width: 640px ) and  (orientation: portrait) {
        text-align: center; } }

    .email {
      display: flex;
      margin-top: 1em;

      @media screen and ( max-width: 640px ) and  (orientation: portrait) {
        flex-direction: column; }

      .email-input {
        width: 350px;
        background: transparent;
        border-width: 0px 0px 2px 0px;
        border-style: solidß;
        border-color: white;
        border-radius: 0px;
        color: white;
        padding-bottom: 10px;
        font-size: 0.5em;

        @media screen and ( max-width: 640px ) and  (orientation: portrait) {
          width: 100%; } }

      .email-input-button {
        width: 150px;
        padding: 10px 20px;
        margin-left: 10px;
        background-color: #0061FF;
        color: #fff;
        border: 0px;
        border-radius: 30px;
        font-size: 0.5em;
        &:hover {
          background-color: #0747A6;
          cursor: pointer; }

        @media screen and ( max-width: 640px ) and  (orientation: portrait) {
          width: 100%;
          padding: 15px;
          margin: 20px 0px 0px 0px; } } } }

  video {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    object-fit: cover;
    &.ended {
      opacity: 0;
      z-index: -1;
      transition: opacity 0.5s; } } }
