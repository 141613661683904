@import '../public';

.section-11 {
  max-width: 1200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  .icon-group {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    @include mobile {
      flex-wrap: wrap; }
    .icon {
      width: 25vmin;
      padding: 1rem;
      @include mobile {
        width: 40%; }
      .image {
        width: 100%; }
      .text {
        text-align: center;
        color: $color-blue-light;
        font-size: 3rem;
        @include mobile {
          font-size: 1.5rem; } } } }

  .content {
    padding: 1rem;
    font-size: 1.5rem;
    @include mobile {
      font-size: 1rem; } } }
